<template>
  <section :class="['slidebar', !isMobile && 'slidebar--open']">
    <div class="slidebar__containerRow">
      <div class="slidebar__containerInput">
        <iconic class="slidebar__iconSearch" name="my_search" />
        <input class="slidebar__inputSearch" type="text" placeholder="Buscar" v-model="search" />
      </div>
      <div class="slidebar__containerIcon" @click="screen < 640 && (isOpen = !isOpen)" v-if="(screen >= 640 && $userData.role === 'superadmin') || screen < 640">
        <i :class="`slidebar__iconChevron fa fa-solid fa-chevron-${isOpen ? 'up' : 'down'}`" v-if="screen < 640" />
        <iconic class="slidebar__iconChevron" name="user-plus" @click.native="showUserCreate" v-else />
      </div>
    </div>
    <template v-if="isOpen">
      <div class="slidebar__containerRow" v-if="$userData.role !== 'monitor' || hasUserPolicySeeModels">
        <div class="slidebar__containerRow">
          <button :class="['slidebar__buttonFilter', filtersData.length && 'slidebar__buttonFilter--active']" @click="(showMenuOrder = false), setShowMenu('showMenu')">
            <iconic class="slidebar__icon" name="filterNew" />
            <span>Filtro</span>
            <span class="slidebar__numberFilters" v-if="filtersData.length">{{ filtersData.length }}</span>
          </button>
          <button :class="['slidebar__buttonFilter', filtersOrder && 'slidebar__buttonFilter--active']" @click="(showMenu = false), setShowMenu('showMenuOrder')">
            <div :class="['slidebar__containerOrder', order === 'down' && 'slidebar__containerOrder--rotate']">
              <iconic :class="['slidebar__icon slidebar__iconOrderUp', filtersOrder && 'slidebar__iconOrderUp--active']" name="orderUp" />
              <iconic class="slidebar__icon slidebar__iconOrderDown" name="orderDown" />
            </div>
            <span>Ordenar</span>
          </button>
        </div>
        <div class="slidebar__containerIcon" @click="showUserCreate" v-if="screen < 640 && $userData.role === 'superadmin'">
          <iconic class="slidebar__iconChevron" name="user-plus" />
        </div>
      </div>
      <div class="slidebar__containerRow slidebar__containerRow--filters">
        <div class="slidebar__filter" v-for="(filter, idx) in getFilters" :key="filter.value + idx">
          <span>{{ filter.value }}</span>
          <iconic class="slidebar__icon slidebar__iconClose" name="closeBold" @click.native="deleteFilter(filter)" />
        </div>
      </div>
      <div class="slidebar__containerReset" v-if="getQuery" @click="resetFilter">
        <iconic class="slidebar__icon" name="closeBold" />
        <span>Eliminar filtros</span>
      </div>
      <div class="slidebar__containerGroups">
        <UsersCard v-for="user in users" :user="user" :key="user._id" />
      </div>
      <ContainerFilters class="slidebar__containerFilters" v-if="showMenu" :close="setShowMenu" />
      <OrderFilters class="slidebar__containerFilters slidebar__containerFilters--order" v-if="showMenuOrder" :close="setShowMenu" />
    </template>
  </section>
</template>

<script>
  import setfilter from "@/mixins/setfilter";
  export default {
    mixins: [setfilter],
    components: {
      UsersCard: () => import("./UsersCard.vue"),
      ContainerFilters: () => import("./MenuFilterUsers.vue"),
      OrderFilters: () => import("./MenuOrderFilters.vue"),
    },
    data() {
      return {
        search: "",
        isOpen: false,
        isMobile: false,
        showMenu: false,
        showMenuOrder: false,
        timeOutSearch: null,
        groups: [
          { _id: "model", name: "modelos", users: [] },
          { _id: "monitor", name: "monitores", users: [] },
          { _id: "coordinator", name: "coordinadores", users: [] },
          { _id: "superadmin", name: "super admin", users: [] },
          { _id: "employee", name: "empleados", users: [] },
        ],
        dicValuesFilter: {
          atoz: "A - Z",
          ztoa: "Z - A",
          newtoold: "nuevos primero",
          oldtonew: "antiguos primero",
          anewtoaold: "actividad reciente",
          aoldtoanew: "actividad antigua",
          endContract: "termina contrato",
          waiver: "renuncia",
          deserter: "deserta",
        },
      };
    },
    computed: {
      screen() {
        return this.$store.state.screen.width;
      },
      users() {
        const users = this.$store.state.user.userList;
        const usersConected = this.$store.state.connectedUsers.users;

        return users.map((user) => {
          const { isOnline = false, isActive = false } = usersConected.find((userConected) => userConected._id === user._id) || {};

          const userToSet = { ...user, isOnline, isActive };
          return userToSet;
        });
      },
      getQuery() {
        const { query } = this.$route;

        const queryParts = Object.entries(query)
          .map(([key, val]) => (val ? `&${key}=${val}` : ""))
          .join("");

        return queryParts;
      },
      getFilters() {
        const { query } = this.$route;

        const filters = Object.entries(query)
          .flatMap(([key, value]) => {
            if (!value) return null;
            const values = value.split("-");
            return values.map((val) => {
              const value = this.dicValuesFilter[val] || this.$global.dictionary.roles_es_auto[val] || this.$store.state.user.companyAreas.find(({ _id }) => _id === val)?.name || val;
              return { key, value };
            });
          })
          .filter(Boolean);

        return filters;
      },
      order() {
        const { order = "atoz" } = this.$route.query;
        return ["atoz", "newtoold", ' "anewtoaold"'].includes(order) ? "down" : "up";
      },
      filtersOrder() {
        const filters = this.getFilters;

        if (!filters.length) {
          return false;
        }

        return filters.some(({ key }) => key === "order");
      },
      filtersData() {
        const filters = this.getFilters;

        if (!filters.length) {
          return false;
        }

        return filters.filter(({ key }) => key !== "order");
      },
      hasUserPolicySeeModels() {
        return this.$userData.polices?.["see-models"]?.status;
      },
    },
    watch: {
      screen(val) {
        this.isOpen = 640 <= val;
        this.isMobile = 640 > val;
      },
      async search(newValue) {
        clearTimeout(this.timeOutSearch);

        this.timeOutSearch = setTimeout(() => {
          this.setFilter({ srcElement: { name: "search", value: newValue } });
        }, 400);
      },
      async getQuery() {
        this.users.length = 0;
        this.$store.dispatch("user/getUsersList", this.getQuery);
      },
      "$route.params.id"() {
        if (this.isOpen && 640 >= this.screen) {
          this.isOpen = false;
        }
      },
    },
    methods: {
      async getAreas() {
        const { data } = await this.$axios.get(`/helpdesk/info?type=area`);

        return data.data;
      },
      resetFilter() {
        this.search = "";
        setTimeout(() => {
          clearTimeout(this.timeOutSearch);
        }, 100);
        this.setFilter("reset");
      },
      setShowMenu(menu) {
        this[menu] = !this[menu];
      },
      deleteFilter({ key, value }) {
        const { query } = this.$route;
        const queryToChange = query[key];
        const values = queryToChange.split("-");

        const partsQuery = values.map((val) => {
          const keyReturn = this.dicValuesFilter[val] || this.$global.dictionary.roles_es_auto[val] || this.$store.state.user.companyAreas.find(({ _id }) => _id === val)?.name || val;
          return keyReturn;
        });

        const result = partsQuery.filter((part) => part !== (this.dicValuesFilter[value] || value)).join("-");

        if (key === "search") this.search = "";
        if (key === "city") this.setFilter({ srcElement: { name: "offices", value: "" } });

        this.setFilter({ srcElement: { name: key, value: result } });
      },
      showUserCreate() {
        this.$store.state.user.toShowInMagnament = 1;
      },
    },
    async mounted() {
      const { search } = this.$route.query;
      if (search) this.search = search;

      this.isOpen = 640 <= this.screen;
      this.isMobile = 640 > this.screen;

      const areas = await this.getAreas();
      this.$store.state.user.companyAreas = areas;

      this.$store.dispatch("user/getUsersList", this.getQuery);

      if (!this.$route.params?.id && this.users?.[0]?._id) {
        this.$router.push({ name: "generalInfo", params: { id: this.users[0]._id } });
      }
    },
  };
</script>

<style lang="scss">
  .slidebar {
    position: relative;
    @include Flex(column, flex-start);
    width: 100%;
    gap: $mpadding;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: $mpadding $mpadding/1.5;
    user-select: none;
    &--open {
      height: 100vh;
    }
    &__containerRow {
      @include Flex(row, space-between);
      width: 100%;
      gap: $mpadding;
      &--filters {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: $mpadding / 2;
      }
    }
    &__containerOrder {
      @include Flex(column);
      &--rotate {
        transform: rotate(180deg);
      }
    }
    &__inputSearch {
      height: 40px;
      border: 1px solid $alto;
      border-radius: 6px;
      padding: 0 0 0 33px;
      width: 100%;
    }
    &__containerInput {
      position: relative;
      width: 100%;
    }
    &__icon {
      font-size: 15px;
      &Close {
        padding: 2px 3px;
        font-size: 12px;
        cursor: pointer;
      }
      &Search,
      &Chevron {
        padding: 5px;
        font-size: 20px;
      }
      &Search {
        position: absolute;
        left: 3px;
        top: 0;
        bottom: 0;
      }
      &OrderUp {
        color: $chicago;
        &--active {
          color: #ffffff;
        }
      }
      &OrderDown {
        margin-top: -3px;
        color: $alto;
      }
      &OrderUp,
      &OrderDown {
        font-size: 12px;
      }
    }
    &__buttonFilter {
      @include Flex(row);
      gap: $mpadding / 2;
      min-width: 90px;
      width: 100%;
      height: 33px;
      color: $chicago;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      border: none;
      transition: 0.2s ease-in-out;
      &--active {
        color: #ffffff;
        background: $primary-color;
      }
      &:hover {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
      }
    }
    &__numberFilters {
      @include Flex(row);
      width: 20px;
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: $primary-color;
      background: #ffffff;
      border-radius: 50%;
    }
    &__containerIcon {
      @include Flex(row);
      width: 50px;
      cursor: pointer;
    }
    &__filter {
      @include Flex(row);
      gap: 5px;
      padding: 2px 8px;
      font-weight: 600;
      font-size: 15px;
      color: $primary-color;
      background: rgba(242, 189, 189, 0.3);
      border-radius: 14px;
    }
    &__containerGroups {
      @include Flex(column, flex-start);
      width: 100%;
      gap: $mpadding / 1.5;
      padding: 2px 5px;
      overflow: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $silver;
        border-radius: 4px;
      }
    }
    &__containerReset {
      @include Flex(row, flex-start);
      gap: $mpadding / 2;
      width: 100%;
      font-weight: 600;
      color: $primary-color;
      cursor: pointer;
    }
    &__containerFilters {
      position: absolute;
      top: 80px;
      left: $mpadding * 2;
    }
    @media (min-width: 640px) {
      padding: $mpadding * 2;
    }
  }
</style>
